.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-width: 200px;
  max-height: 200px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  margin: 0 0.5rem;
}

.outlined-button {
  color: #2196f3; /* set the text color */
  border: 2px solid #2196f3; /* add a border */
  border-radius: 4px; /* round the edges */
  padding: 0.5rem 1rem; /* add some padding */
  background-color: transparent; /* make the background transparent */
  transition: all 0.3s ease-in-out; /* add a transition effect */
  cursor: pointer;
}

.outlined-button:hover,
.outlined-button:focus,
.outlined-button:active {
  color: #fff; /* change the text color on hover */
  background-color: #2196f3; /* add a background color on hover */
  border-color: #2196f3; /* change the border color on hover */
}

.text-container {
  text-align: center; /* center align the text */
  margin-top: 0.5rem; /* reduce the margin above the text */
  margin-bottom: 1rem; /* reduce the margin below the text */
}

.large-text {
  font-size: 1.5rem; /* set the font size for the large text */
  margin-bottom: 0.5rem;
}

.medium-text {
  font-size: 1rem; /* set the font size for the medium text */
  color: #6c757d; /* set the color to secondary */
}

.small-text {
  font-size: 0.9rem; /* set the font size for the small text */
  color: #7e8992; /* set the color to secondary */
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa; /* set the background color of the footer */
  text-align: center; /* center align the footer content */
  padding: 1rem; /* add some padding to the footer */
  font-size: 0.8rem; /* adjust the font size of the footer text */
  color: #6c757d; /* set the color of the footer text */
}

.footer a {
  color: #2d90fa; /* set the link color */
  text-decoration: none; /* remove underline from the link */
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
}